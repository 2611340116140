<template>
  <div>
    <form @submit.prevent="submit" class="w-9/10 m-auto signup-form">
      <h6 class="font-bold mb-1 text-4xl">Create Account</h6>

      <div class="mb-12 font-normal">
        Sign up and earn up to 4% on every successful investment referral
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="col-span-1">
          <form-group
            name="first_name"
            :form="form"
            v-model="form.data.first_name.value"
          >
            First Name
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            name="last_name"
            :form="form"
            v-model="form.data.last_name.value"
          >
            Last Name
          </form-group>
        </div>
      </div>

      <form-group
        name="phone_no"
        :form="form"
        v-model="form.data.phone_no.value"
      >
        Phone number
      </form-group>

      <form-group name="email" :form="form" v-model="form.data.email.value">
        Email address
      </form-group>

      <form-group
        type="password"
        name="password"
        :form="form"
        v-model="form.data.password.value"
      >
        Password
      </form-group>

      <form-group
        type="phone"
        leftIcon="call-outline"
        name="originator"
        :form="form"
        v-model="form.data.originator.value"
      >
        Referred By Phone No/Email
      </form-group>
 

      <div class=" mt-8 mb-10">
        <div class="flex flex-row items-center mt-8 mb-2">
          <checkbox id="terms" class="mr-3" v-model="form.data.terms.value" />
          <label for="terms" class="text-xs"
            >I accept the
            <router-link class="underline" :to="{ name: 'terms' }">
              Terms & Conditions
            </router-link>
          </label>
        </div>

        <div class="alert alert-red-soft" v-if="getFirstError('terms')">
          <span class="alert-icon">!</span>
          <span>You need to accept the terms and conditions</span>
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-blue btn-md w-full relative"
        :disabled="form.loading"
      >
        <span v-if="form.loading">Submitting...</span>
        <span v-else>Register</span>
      </button>
    </form>
    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <!-- <img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark"> -->

      <div class="text-lg font-bold mb-4">
        Registration successful!
      </div>

      <router-link class="btn btn-blue mr-2 mb-2" :to="{ name: 'dashboard' }">
        Go to dashboard
      </router-link>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10"
      ref="passwordResetModal"
      @close="resetPasswordResetForm"
    >
      <template v-if="forgotPasswordForm.success">
        <h3 class="text-xl font-bold mb-3">Password Request Submitted.</h3>

        <p class="text-sm opacity-50 mb-10">
          Please check your mail for a link to reset your password. Don't forget
          to check your spam folder id you can't find it.
        </p>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-blue btn-md w-full"
            @click.prevent="$refs.passwordResetModal.close"
          >
            Okay
          </button>
        </div>
      </template>

      <template v-else>
        <div class="mb-16">
          <h3 class="text-xl font-bold mb-3">Did you forget your password?</h3>

          <p class="text-sm opacity-50">
            Enter your email address below to find your account.
          </p>
        </div>

        <form @submit.prevent="forgotPassword">
          <template v-if="getFormError(forgotPasswordForm)">
            <div class="alert alert-red-soft -mt-8 mb-12">
              <div class="alert-icon mr-3">
                !
              </div>
              <div class="text-xs font-normal">
                {{ getFormError(forgotPasswordForm) }}
              </div>
            </div>
          </template>

          <form-group
            type="email"
            name="email"
            :form="forgotPasswordForm"
            v-model="forgotPasswordForm.data.email.value"
          >
            Email Address
          </form-group>

          <div class="text-center">
            <button
              type="submit"
              class="btn btn-blue btn-md w-full"
              :disabled="forgotPasswordForm.loading"
            >
              <span v-if="forgotPasswordForm.loading">Finding Account...</span>
              <span v-else>Find My Account</span>
            </button>
          </div>
        </form>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bag: require("@/assets/icons/money-bag-black.svg"),
      form: this.$options.basicForm([
        "first_name",
        "last_name",
        { name: "phone_no", rules: "required|phone" },
        { name: "email", rules: "required|email" },
        { name: "password", rules: "required|min:8" },
        { name: "originator", rules: "nullable" },
        { name: "terms", value: false, rules: "required" },
      ]),
      forgotPasswordForm: this.$options.basicForm([
        {
          name: "email",
          rules: "required|email",
        },
      ]),
      selectedPlan: null,
      selectedPlanError: null,
      products: this.$options.resource({}),
      selectedProduct: null,
    };
  },
  mounted() {
    // this.$options.basicWatchers(this, "form");
    // if (this.completed) {
    //   this.mapUserData();
    //   // this.form.data.employment_status.value == 'employed';
    // }
  },
  watch: {
    name() {
      this.$emit("name-changed", {
        first_name: this.form.data.first_name.value,
        last_name: this.form.data.last_name.value,
      });
    },
  },
  beforeMount() {
    this.getProducts();
  },
  methods: {
    selectPlan(plan) {
      this.selectedPlan = plan;
      // if (plan === "standard_loan") {
      //   this.selectedProduct = this.products.data.filter(
      //     (product) => product.product === "standard-loan"
      //   );
      // } else if (plan === "credit_card") {
      //   this.selectedProduct = this.products.data.filter(
      //     (product) => product.product === "credit-card"
      //   );
      // }
    },
    async forgotPassword() {
      if (!this.validateForm(this.forgotPasswordForm)) {
        return false;
      }

      this.forgotPasswordForm.error = false;
      this.forgotPasswordForm.loading = true;

      await this.$post({
        url: `${this.$baseurl}/password/request`,
        data: {
          email: this.forgotPasswordForm.data.email.value,
        },
        success: () => {
          this.forgotPasswordForm.success = true;
        },
        error: (error) => {
          this.forgotPasswordForm.error = error;
          this.mapFormErrors(
            this.forgotPasswordForm,
            error?.response?.data?.errors
          );
        },
      });
      this.forgotPasswordForm.loading = false;
    },
    navigate() {
      this.$router.push({ name: "root" });
    },
    openPasswordResetModal() {
      this.$refs.passwordResetModal.open();
    },
    resetPasswordResetForm() {
      setTimeout(() => {
        this.forgotPasswordForm = this.resetForm(this.forgotPasswordForm);
      }, 500);
    },
    async getProducts() {
      await this.$get({
        url: `${this.$baseurl}/affiliates/products`,
        success: (response) => {
          this.products.data = response.data.data.data;
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });
    },
    async submit() {
      if (!this.validateForm(this.form)) {
        return false;
      }
      // if (!this.selectedPlan) {
      //   this.selectedPlanError = "Please select a plan to sell.";
      //   return false;
      // }

      this.form.error = false;
      this.form.loading = true;

      await this.$post({
        url: `${this.$baseurl}/affiliate/register`,
        data: {
          email: this.form.data.email.value,
          first_name: this.form.data.first_name.value,
          last_name: this.form.data.last_name.value,
          phone: this.form.data.phone_no.value,
          password: this.form.data.password.value,
          product_id: 'a279781c-ca0a-43ca-bfd8-e3780a76cda0',
          originator: this.form.data.originator.value,
        },
        success: () => {
          // this.$refs.successModal.open();
          this.login();
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });
      this.form.loading = false;
    },
    async login() {
      this.form.error = false;
      this.form.loading = true;
      await this.$post({
        url: `${this.$baseurl}/affiliate/login`,
        data: {
          email: this.form.data.email.value,
          password: this.form.data.password.value,
        },
        success: (response) => {
          this.$store.dispatch("session/login", {
            token: response.data.data.token,
            user: response.data.data.affiliate,
            remember: true,
          });

          this.$root.$emit("login");
          this.$root.$emit("global", {
            event: "loggedIn",
            params: [response.data.data.affiliate],
          });

          if (response.data.data.affiliate.change_password_at) {
            this.$router.push({ name: "dashboard" });
          } else {
            this.$router.push({ name: "update-password" });
          }
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        },
      });

      this.form.loading = false;
    },
  },
};
</script>
<style scoped>
.check-signup {
  top: -10%;
  right: -10%;
}
.signup-form {
  max-width: 489px;
}
</style>
